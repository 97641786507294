.prices {
    display: flex;
    justify-content: space-between;

    @include vp-767 {
        flex-direction: column;
    }

    &.left {
        .prices__info {
            text-align: end;

            p {
                margin-left: auto;
            }

            .table {
                margin-left: auto;
            }

            @include vp-767 {
                text-align: center;
                p {
                    margin-inline: auto;
                }

                .table {
                    margin-inline: auto;
                }
            }
        }

        .prices__img {
            order: -1;

            @include vp-767 {
                order: 1;
            }
        }
    }


    &__info {
        padding-top: 34px;
        margin-right: 16px;
        flex: 1 1;
        white-space: pre-line;

        p {
            max-width: 80%;
            margin-bottom: 24px;

            @include vp-767 {
                max-width: 100%;
            }
        }


        @include vp-1439 {
            padding-top: 0;
        }

        @include vp-1023 {
            padding-bottom: 16px;
        }

        @include vp-767 {
            margin-right: 0;
        }

        a {
            position: relative;
            margin-left: 7px;
            width: fit-content;
            text-transform: uppercase;
            font-weight: 700;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: 0.03em;
            color: #22d695;
            transition: all .3s;

            &:hover::after {
                content: "";
                position: absolute;
                left: 0;
                bottom: 0;
                width: 100%;
                height: 1px;
                background-color: #22d695;
            }

            &:focus {
                margin-top: -4px;
                margin-left: 3px;
                padding: 3px;
                border: 1px solid #f845fc;
                border-radius: 4px;
                outline: none;
            }

            &:focus::after {
                content: "";
                position: absolute;
                left: 0;
                bottom: 2px;
                width: 100%;
                height: 1px;
                background-color: #22d695;
            }


        }
    }

    &__img {
        border-radius: 32px;
        background-color: $color-ghost-white;
        align-self: center;
        flex: 1 1;

        img {
            width: 97%;
            height: auto;
            object-fit: cover;
        }

        @include vp-767 {
            align-self: stretch;
            margin: 0 -16px;
        }
    }
}
